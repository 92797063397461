import React from "react";
import "./Slide.scss";
import holiday from "../../Images/holiday.jpg";
import dimsum from "../../Images/dimsum.jpeg";
import hibachi from "../../Images/hibachi.jpeg";
import sushi from "../../Images/sushi.jpeg";
import Carousel from "react-material-ui-carousel";
import { useNavigate } from "react-router-dom";

const SlideItem = ({ children }) => {
  return (
    <div className="slide-item-container">
      {children.map((child) => (
        <span className="slide-item">{child}</span>
      ))}
    </div>
  );
};

const BasicCard = ({ children, header, subheader, buttonText, onClick }) => {
  return (
    <div className="basic-slide-card">
      <span className="text">
        <p className="header">{header}</p>
        <p className="subheader">{subheader}</p>
        {children}
      </span>
      {buttonText && (
        <div className="button" onClick={onClick}>
          {buttonText}
        </div>
      )}
    </div>
  );
};

const Slide = () => {
  const navigate = useNavigate();

  return (
    <section className="slide-container">
      <Carousel animation="slide" duration={1000} indicators={false}>
        <SlideItem>
          <img
            src={holiday}
            alt="Christmas lights wrapped around snowy branch"
          />
          <BasicCard
            header="2024 Holiday Hours"
            buttonText="Get Directions"
            onClick={() =>
              window.open(
                "https://maps.google.com/?q=New Asia House 300 Quaker Ln STE 23, Warwick, RI 02886",
                "_blank"
              )
            }
          >
            <table style={{ fontSize: "24px" }}>
              <tr>
                <td>Thanksgiving Day</td>
                <td>11:00am-5:00pm</td>
              </tr>
              <tr>
                <td>Chistmas Eve</td>
                <td>11:00am-11:00pm</td>
              </tr>
              <tr>
                <td>Chistmas Day</td>
                <td>11:00am-11:00pm</td>
              </tr>
              <tr>
                <td>New Year's Eve</td>
                <td>11:00am-11:00pm</td>
              </tr>
              <tr>
                <td>New Year's Day</td>
                <td>11:00am-11:00pm</td>
              </tr>
            </table>
          </BasicCard>
        </SlideItem>
        <SlideItem>
          <img src={dimsum} alt="Picture of a variety of dim sum" />
          <BasicCard
            header="Dim Sum Available!"
            subheader="We have a variety of options of dim sum delicacies available. Please call us at (401)-826-8200 to make sure on our dim sum availabilities"
            buttonText="Call Us"
            onClick={() => window.open("tel:+14018268200", "_system")}
          />
        </SlideItem>
        <SlideItem>
          <BasicCard
            header="Sushi"
            subheader="We have delicious assortments and options of sushi ranging from our Chef Special Rolls, Nigiri, Sashimi, and Sushi Entrees"
            buttonText="View Menu"
            onClick={() => navigate("/menu")}
          />
          <img src={sushi} alt="Variety of sushi" />
        </SlideItem>
      </Carousel>
      <div className="about-us">
        <p className="header">About Us</p>
        <p className="description">
          Welcome to New Asia House, where we've been delighting taste buds with
          our delectable Chinese cuisine since September 2016! Step into our
          inviting space, where the rich flavors of authentic Chinese classics
          blend seamlessly with a modern dining experience. Explore our menu
          filled with mouthwatering Chinese favorites, and savor the taste of
          tradition with every bite. Join us for a dining experience where
          simplicity meets sensational flavors. Welcome to New Asia House—your
          destination for the best in Chinese dining!
        </p>
      </div>
    </section>
  );
};

export default Slide;
